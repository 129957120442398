import { useMutation } from "@tanstack/react-query";
import { client } from "./api-client";

export const useUploadMedia = () => {
  return useMutation(async (file) => {
    const response = await client(`media-upload/mediaFiles/coins`, {
      method: "POST",
      data: file,
    });
    return response.url;
  });
};
