import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  useAddKycTwoDocument,
  useGetKycLimits,
  useGetKycTwoDocumentsForAdmin,
  useUpdateKycLimits,
} from "utils/api/auth.api";
import {
  useGetKycTransactionLimit,
  useSetKycTransactionLimit,
} from "utils/api/user.api";

// interface Option {
//   value: string;
//   label: string;
// }

// const options: any = [
//   { value: "Id", label: "ID Card" },
//   { value: "License", label: "Drivers License" },
//   { value: "Passport", label: "Passport" },
// ];

const ManageNotesDocs = () => {
  const { data: kyc1Data } = useGetKycTransactionLimit("Kyc1 Limit");
  const { data: kyc2Data } = useGetKycTransactionLimit("Kyc2 Limit");
  const { data: kycSettingData } = useGetKycLimits();
  const updateKycLimitMutation = useUpdateKycLimits();
  const setKycTransactionLimitMutation = useSetKycTransactionLimit();
  // const { data: instructionsData } = useGetKycTwoDocumentsForAdmin();
  const getKycTwoDocumentMutation = useGetKycTwoDocumentsForAdmin();

  const addKycTwoDocumentMutation = useAddKycTwoDocument();

  const [activeTab, setActiveTab] = useState<string>("KYC1");
  const [kyc1Checked, setKyc1Checked] = useState(false);
  const [kyc2Checked, setKyc2Checked] = useState(false);
  const [note, setNote] = useState("");
  const [note2, setNote2] = useState("");
  const [instruction, setInstruction] = useState("");
  const [limitData, setLimitData] = useState({
    swapUpperLimit: 0,
    withdrawUpperLimit: 0,
  });
  const [limitData2, setLimitData2] = useState({
    swapUpperLimit2: 0,
    withdrawUpperLimit2: 0,
  });

  const kyc1Object = kycSettingData?.find((obj) => obj.name === "Kyc1 Limit");
  const kyc2Object = kycSettingData?.find((obj) => obj.name === "Kyc2 Limit");

  const handleKyc1Change = () => {
    setKyc1Checked(!kyc1Checked);
    if (!kyc1Checked) {
      setKyc2Checked(false);
    }
  };

  const handleKyc2Change = () => {
    setKyc2Checked(!kyc2Checked);
  };

  // const handleCheckboxChange = (value: any) => {
  //   if (selectedOptions.includes(value)) {
  //     setSelectedOptions(selectedOptions.filter((option) => option !== value));
  //   } else {
  //     setSelectedOptions([...selectedOptions, value]);
  //   }
  // };

  useEffect(() => {
    if (kyc1Data) {
      setLimitData({
        swapUpperLimit: kyc1Data?.swapUpperLimit,
        withdrawUpperLimit: kyc1Data?.withdrawUpperLimit,
      });
    }
    if (kyc2Data) {
      setLimitData2({
        swapUpperLimit2: kyc2Data?.swapUpperLimit,
        withdrawUpperLimit2: kyc2Data?.withdrawUpperLimit,
      });
    }
  }, [kyc1Data, kyc2Data]);

  useEffect(() => {
    if (kycSettingData) {
      setKyc1Checked(kyc1Object?.isActive);
      setKyc2Checked(kyc2Object?.isActive);
      setNote(kyc1Object?.kycNote);
      setNote2(kyc2Object?.kycNote);
    }
  }, [kycSettingData]);

  useEffect(() => {
    const getDocumentData = async () => {
      const res = await getKycTwoDocumentMutation.mutateAsync();
      const formattedInstruction = res?.documents?.replace(/\/n/g, "\n");
      setInstruction(formattedInstruction);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getDocumentData();
  }, []);

  console.log(instruction);

  return (
    <div>
      <ul className="flex flex-wrap items-center gap-3 mb-4 mt-8">
        <li>
          <div
            onClick={() => setActiveTab("KYC1")}
            className={` ${
              activeTab === "KYC1" ? "text-black-900" : "text-black-900/20"
            } text-xl cursor-pointer`}
          >
            KYC1
          </div>
        </li>
        <li>
          <div
            onClick={() => setActiveTab("KYC2")}
            className={` ${
              activeTab === "KYC2" ? "text-black-900" : "text-black-900/20"
            } text-xl cursor-pointer`}
          >
            KYC2
          </div>
        </li>
      </ul>
      <div className="bg-cyan-300 rounded-lg p-5">
        <div className="flex items-center justify-between">
          <div className="text-left flex items-center justify-start mr-4 ">
            {activeTab === "KYC1" && (
              <label className="inline-flex items-center cursor-pointer">
                <span className="mr-3  font-medium ">KYC 1</span>
                <input
                  type="checkbox"
                  checked={kyc1Checked}
                  onChange={handleKyc1Change}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-gray-300 dark:peer-focus:ring-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-cyan-800"></div>
              </label>
            )}
            {activeTab === "KYC2" && (
              <label className="inline-flex items-center cursor-pointer">
                <span className="mr-3  font-medium ">KYC 2</span>
                <input
                  type="checkbox"
                  checked={!kyc1Checked ? false : kyc2Checked}
                  onChange={handleKyc2Change}
                  disabled={!kyc1Checked}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-gray-300 dark:peer-focus:ring-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-cyan-800"></div>
              </label>
            )}
          </div>
          {/* {activeTab === "KYC1" && (
            <div className="mt-2">
              <label
                htmlFor="type"
                className="block text-sm font-semibold leading-6 text-gray-900 text-left"
              >
                KYC Document Types:
              </label>
              <div className="mt-2 flex gap-4">
                {options.map((option: any) => (
                  <div
                    key={option.value}
                    className="flex items-center cursor-pointer mr-1"
                  >
                    <label className="mr-1" htmlFor={option.value}>
                      {option.label}
                    </label>
                    <input
                      id={option.value}
                      type="checkbox"
                      value={option.value}
                      checked={selectedOptions.includes(option.value)}
                      onChange={() => handleCheckboxChange(option.value)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )} */}
        </div>

        <div className="mt-4">
          {activeTab === "KYC1" && (
            <div className="mt-4">
              <label
                htmlFor="note"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Kyc Note
              </label>
              <div className="mt-2">
                <textarea
                  value={note}
                  onChange={(e: any) => setNote(e.target.value)}
                  id="note"
                  name="note"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          )}

          {activeTab === "KYC2" && (
            <>
              <div className="mt-8">
                <label
                  htmlFor="notes"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  KYC Note
                </label>
                <div className="mt-2">
                  <textarea
                    value={note2}
                    onChange={(e: any) => setNote2(e.target.value)}
                    id="notes"
                    name="notes"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="mt-8">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Instructions
                </label>
                <div className="mt-2">
                  <textarea
                    style={{ whiteSpace: "pre-line" }}
                    value={instruction}
                    onChange={(e: any) => setInstruction(e.target.value)}
                    id="description"
                    name="description"
                    placeholder=" Each instruction must start in a new line by pressing enter"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>
                <p>
                  Each instruction must start in a new line by pressing enter
                </p>
              </div>
            </>
          )}
        </div>
        <div className=" py-3 sm:flex sm:flex-row-reverse  gap-3">
          <button
            className="bg-cyan-800  border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-4 py-2 sm:text-sm"
            disabled={updateKycLimitMutation?.isLoading}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => {
              if (activeTab === "KYC1") {
                try {
                  const formData = {
                    recordId: kyc1Data?.id,
                    isActive: kyc1Checked,
                    kycNote: note,
                  };
                  await updateKycLimitMutation.mutateAsync(formData);
                  toast.success("KYC 1 Settings updated successfully!!!");
                } catch (error: any) {
                  toast.error(error?.message);
                }
              } else {
                try {
                  const formData = {
                    recordId: kyc2Data?.id,
                    isActive: kyc2Checked,
                    kycNote: note2,
                  };
                  await updateKycLimitMutation.mutateAsync(formData);
                  await addKycTwoDocumentMutation.mutateAsync({
                    documents: instruction,
                  });
                  toast.success("KYC 2 Settings updated successfully!!!");
                } catch (error: any) {
                  toast.error(error?.message);
                }
              }
            }}
          >
            {updateKycLimitMutation?.isLoading ? (
              <div
                className="w-5 h-5 rounded-full animate-spin mx-auto
         border-2 border-solid border-black-800 border-t-transparent"
              ></div>
            ) : (
              "Save"
            )}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          >
            Cancel
          </button>
        </div>
      </div>
      {
        activeTab === "KYC1" ? (
          // keys?.map((apiKey: any) => (
          <div
            key={kyc1Data?.id}
            className={`bg-cyan-300 rounded-lg flex items-center p-5 gap-4 mt-5 justify-between`}
          >
            <p className="text-lg font-bold  flex items-center gap-4 w-[200px] ">
              {kyc1Data?.name}
            </p>
            <div className={`bg-cyan-300 rounded-lg flex items-center  gap-3 `}>
              <div className=" text-right">
                <span className="block text-xs ">Swap Limit</span>
                <span className="font-bold text-[14px]">
                  {/* {getFee("withdraw_fiat_fee")}% */}
                  {kyc1Data?.swapUpperLimit}
                </span>
              </div>
              <input
                type="number"
                min={0}
                placeholder="Enter new Swap Limit"
                value={limitData?.swapUpperLimit}
                onChange={(e: any) => {
                  const value = Math.max(0, parseInt(e.target.value));
                  setLimitData({ ...limitData, swapUpperLimit: value });
                }}
                className="block rounded-md border-0 px-1.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                // onClick={() => handleUpdate(keys[0].id)}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={async () => {
                  try {
                    const formData = {
                      name: kyc1Data?.name,
                      swapUpperLimit: limitData?.swapUpperLimit,
                      isActive: true,
                    };
                    await setKycTransactionLimitMutation.mutateAsync(formData);
                    toast.success("Swap Updated Successfully!!!");
                  } catch (error: any) {
                    toast.error(error.message);
                  }
                }}
                className="bg-cyan-800  border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-2 py-2 sm:text-sm"
              >
                Update
              </button>
            </div>
            <div
              className={`bg-cyan-300 rounded-lg flex items-center   gap-4 `}
            >
              <div className="text-right">
                <span className="block text-xs ">Withdraw Limit</span>
                <span className="font-bold text-[14px]">
                  {/* {getFee("withdraw_fiat_fee")}% */}
                  {kyc1Data?.withdrawUpperLimit}
                </span>
              </div>
              <input
                type="number"
                min={0}
                placeholder="Enter new fee"
                value={limitData?.withdrawUpperLimit}
                onChange={(e: any) => {
                  const value = Math.max(0, parseInt(e.target.value));
                  setLimitData({
                    ...limitData,
                    withdrawUpperLimit: value,
                  });
                }}
                // onChange={(e) =>
                // handleInputChange(e, keys[0].id, "withdrawLimit")
                // }
                className="block  rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                // onClick={() => handleUpdate(keys[0].id)}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={async () => {
                  try {
                    const formData = {
                      name: kyc1Data?.name,
                      withdrawUpperLimit: limitData?.withdrawUpperLimit,
                      isActive: true,
                    };
                    await setKycTransactionLimitMutation.mutateAsync(formData);
                    toast.success("Withdraw Updated Successfully!!!");
                  } catch (error: any) {
                    toast.error(error.message);
                  }
                }}
                className="bg-cyan-800 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-2 py-2 sm:text-sm"
              >
                Update
              </button>
            </div>
          </div>
        ) : (
          // ))
          // keys?.map((apiKey: any) => (
          <div
            key={kyc2Data?.id}
            className={`bg-cyan-300 rounded-lg flex items-center p-5 gap-4 mt-5 justify-between`}
          >
            <p className="text-lg font-bold  flex items-center gap-4 w-[200px] ">
              {kyc2Data?.name}
            </p>
            <div className={`bg-cyan-300 rounded-lg flex items-center  gap-3 `}>
              <div className=" text-right">
                <span className="block text-xs ">Swap Limit</span>
                <span className="font-bold text-[14px]">
                  {/* {getFee("withdraw_fiat_fee")}% */}
                  {kyc2Data?.swapUpperLimit}
                </span>
              </div>
              <input
                type="number"
                min={0}
                placeholder="Enter new Swap Limit"
                value={limitData2?.swapUpperLimit2}
                onChange={(e: any) => {
                  const value = Math.max(0, parseInt(e.target.value));
                  setLimitData2({
                    ...limitData2,
                    swapUpperLimit2: value,
                  });
                }}
                className="block rounded-md border-0 px-1.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={async () => {
                  try {
                    const formData = {
                      name: kyc2Data?.name,
                      swapUpperLimit: limitData2?.swapUpperLimit2,
                      isActive: true,
                    };
                    await setKycTransactionLimitMutation.mutateAsync(formData);
                    toast.success("Swap Updated Successfully!!!");
                  } catch (error: any) {
                    toast.error(error.message);
                  }
                }}
                disabled={setKycTransactionLimitMutation?.isLoading}
                className="bg-cyan-800  border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-2 py-2 sm:text-sm"
              >
                Update
              </button>
            </div>
            <div
              className={`bg-cyan-300 rounded-lg flex items-center   gap-4 `}
            >
              <div className="text-right">
                <span className="block text-xs ">Withdraw Limit</span>
                <span className="font-bold text-[14px]">
                  {/* {getFee("withdraw_fiat_fee")}% */}
                  {kyc2Data?.withdrawUpperLimit}
                </span>
              </div>
              <input
                type="number"
                placeholder="Enter new fee"
                min={0}
                value={limitData2?.withdrawUpperLimit2}
                onChange={(e: any) => {
                  const value = Math.max(0, parseInt(e.target.value));
                  setLimitData2({
                    ...limitData2,
                    withdrawUpperLimit2: value,
                  });
                }}
                className="block  rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={async () => {
                  try {
                    const formData = {
                      name: kyc2Data?.name,
                      withdrawUpperLimit: limitData2?.withdrawUpperLimit2,
                      isActive: true,
                    };
                    await setKycTransactionLimitMutation.mutateAsync(formData);
                    toast.success("Withdraw Updated Successfully!!!");
                  } catch (error: any) {
                    toast.error(error.message);
                  }
                }}
                disabled={setKycTransactionLimitMutation?.isLoading}
                className="bg-cyan-800 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-2 py-2 sm:text-sm"
              >
                Update
              </button>
            </div>
          </div>
        )
        // ))
      }
      {/* </div> */}
    </div>
  );
};

export default ManageNotesDocs;
