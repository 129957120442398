import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";
import {
  type CustomerMessage,
  type NotificationResponse,
} from "utils/types/notification.type";

export const getNotificationHistory = () =>
  useQuery<NotificationResponse, Error>(
    ["getNotificationHistory"],
    async () => await client(`auth/getNotificationHistory?offset=0&limit=10`)
  );

export const sendMessageManuallyToCustomers = () => {
  const queryClient = useQueryClient();

  return useMutation<{ message: string }, Error, CustomerMessage>(
    async (data) => {
      const res = await client(
        `auth/sendMessageManuallyToCustomers?title=${data?.title}&notificationIcon=${data?.notificationIcon}&notificationType=${data?.notificationType}&message=${data?.message}`,
        {
          method: "POST",
        }
      );

      return res;
    },
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getNotificationHistory"]);
      },
    }
  );
};
