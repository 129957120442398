import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { sendMessageManuallyToCustomers } from "utils/api/notification.api";

const options = [
  { value: "Danger", label: "Danger" },
  { value: "Warning", label: "Warning" },
  { value: "Success", label: "Success" },
  { value: "Info", label: "Info" },
  { value: "Etc", label: "Etc" },
];

const optionsType = [
  { value: "Alert", label: "Alert" },
  { value: "Message", label: "Message" },
];

const CreateNotification = ({ isOpen, setIsOpen }: any) => {
  // sending data to server
  const CustomerMessage = sendMessageManuallyToCustomers();

  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [notificationIcon, setNotificationIcon] = useState("");
  const [notificationType, setNotificationType] = useState("");

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-4 sm:pb-4">
                  <div className="">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Create Notification
                      </Dialog.Title>
                      <div className="mt-4">
                        <div className="mt-2">
                          <label
                            htmlFor="title"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Title
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={(e) => setTitle(e.target.value)}
                              value={title}
                              id="title"
                              name="title"
                              type="text"
                              required
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            />
                          </div>

                          <div className="mt-2 ">
                            <label
                              htmlFor="icon"
                              className="mt-2 mb-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              Notification Icon
                            </label>
                            <Select
                              options={options}
                              onChange={(value: any) =>
                                setNotificationIcon(value.value)
                              }
                            />
                          </div>
                          <div className="mt-2 mb-2">
                            <label
                              htmlFor="type"
                              className="mt-2 mb-2 block text-sm font-medium leading-6 text-gray-900"
                            >
                              Notification Type
                            </label>
                            <Select
                              options={optionsType}
                              onChange={(value: any) =>
                                setNotificationType(value.value)
                              }
                            />
                          </div>
                          <label
                            htmlFor="message"
                            className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                          >
                            Message
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={(e) => setMessage(e.target.value)}
                              value={message}
                              id="message"
                              name="message"
                              type="text"
                              required
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-cyan-300 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-cyan-800 sm:ml-3 sm:w-auto ring-1 ring-inset ring-cyan-800"
                    // onClick={() => setIsOpen(false)}
                    onClick={() => {
                      if (
                        title === "" ||
                        message === "" ||
                        notificationIcon === "" ||
                        notificationType === ""
                      ) {
                        toast.error("Please fill all fields");
                        return;
                      }
                      CustomerMessage?.mutateAsync({
                        title,
                        message,
                        notificationIcon,
                        notificationType,
                      })
                        .then((res: any) => {
                          if (res) {
                            setTitle("");
                            setMessage("");
                            setIsOpen(false);
                            toast.success("Message send successfully!");
                          }
                        })
                        .catch((err: any) => {
                          toast.error(err?.message || "Something went wrong");
                        });
                    }}
                    disabled={CustomerMessage?.isLoading}
                  >
                    {CustomerMessage?.isLoading ? (
                      <div
                        className="w-5 h-5 rounded-full animate-spin mx-auto
                      border-2 border-solid border-black-800 border-t-transparent"
                      ></div>
                    ) : (
                      "Save"
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateNotification;
