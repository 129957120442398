import Layout from "layout";
import React, { useState } from "react";
import AvailableCoins from "views/availableCoins";

import WithdrawalLimits from "views/withdrawalLimits";

const WithdrawalSettings = () => {
  const [activeTab, setActiveTab] = useState("available");

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <ul className="flex flex-wrap items-center gap-3 mb-4">
            <li>
              <div
                onClick={() => setActiveTab("available")}
                className={` ${
                  activeTab === "available"
                    ? "text-black-900"
                    : "text-black-900/20"
                } text-xl cursor-pointer`}
              >
                Coins
              </div>
            </li>
            <li>
              <div
                onClick={() => setActiveTab("limits")}
                className={` ${
                  activeTab === "limits"
                    ? "text-black-900"
                    : "text-black-900/20"
                } text-xl cursor-pointer`}
              >
                Withdrawal Limits
              </div>
            </li>
          </ul>
          {activeTab === "available" ? (
            <AvailableCoins />
          ) : activeTab === "limits" ? (
            <WithdrawalLimits />
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default WithdrawalSettings;
